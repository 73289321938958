'use client';

import { useConfetti } from '@/lib/hooks/use-confetti';
import { CheckCircle, LoaderCircle, XCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Button } from '../ui/button';
import { Dialog, DialogContent } from '../ui/dialog';
import { fulfillCheckout } from './actions';
export function CheckoutRedirectModal() {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<'loading' | 'success' | 'error'>('loading');
  const [message, setMessage] = useState<string | null>(null);
  const {
    trigger
  } = useConfetti();
  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get('checkout_session_id');
    if (sessionId) {
      setOpen(true);
      fulfillCheckout(sessionId).then(amount => {
        setState('success');
        setMessage(amount);
        trigger();
      }).catch(error => {
        setState('error');
        setMessage(error.message);
        console.error(error);
      });
    }
  }, [trigger]);
  return <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-component="CheckoutRedirectModal" data-sentry-source-file="checkout-redirect-modal.tsx">
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="checkout-redirect-modal.tsx">
        <div className="flex flex-col items-center justify-center gap-8 py-4">
          {state === 'loading' ? <>
              <LoaderCircle strokeWidth={1} className="size-12 !animate-spin" />
              <p className="text-center text-gray-600">
                Awaiting payment confirmation...
              </p>
            </> : state === 'success' ? <>
              <CheckCircle strokeWidth={1} className="size-12 text-green-500 animate-in slide-in-from-bottom-2" />
              <div>
                <p className="text-center text-xl font-bold animate-in slide-in-from-bottom-2">
                  Payment confirmed!
                </p>
                <p className="mb-4 text-center text-gray-600">
                  We added {message} in credits to your account.
                </p>
              </div>
              <Button onClick={() => {
            const url = new URL(window.location.href);
            url.searchParams.delete('checkout_session_id');
            window.history.replaceState({}, '', url.toString());
            setOpen(false);
          }} className="animate-in *:slide-in-from-bottom-2">
                Back to chatting
              </Button>
            </> : <>
              <XCircle strokeWidth={1} className="size-12 text-red-500" />
              <div>
                <p className="text-center text-xl font-bold">Payment failed!</p>
                <p className="mb-4 text-center text-gray-600">
                  {message || 'Something went wrong'}
                </p>
              </div>
            </>}
        </div>
      </DialogContent>
    </Dialog>;
}