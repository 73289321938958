import { fetchModels, SupabaseModel } from '@/lib/supabase/actions';
import { createBrowserSupabase } from '@/lib/supabase/browser';
import { cn } from '@/lib/utils';
import { captureException } from '@sentry/nextjs';
import { ArrowRight, Bot, CircleAlert, Coins, FlaskConical, Info, LoaderCircle, MessageCircle } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useEffect, useState, useTransition } from 'react';
import { toast } from 'sonner';
import { ModelPicker } from '../chat/model-picker';
import { FairLogo } from '../fair-logo';
import { Button } from '../ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../ui/card';
import { Checkbox } from '../ui/checkbox';
import { Input } from '../ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Separator } from '../ui/separator';
import { addUserToResend, createBetaTesterCoupon, createStartingBonusTransaction } from './actions';
import { OnboardingContentProps } from './config';
export function WelcomeToBetaTesting({
  moveToNextStep
}: OnboardingContentProps) {
  return <div className="flex flex-col gap-8 animate-in fade-in slide-in-from-bottom-4" data-sentry-component="WelcomeToBetaTesting" data-sentry-source-file="onboarding-steps.tsx">
      <p className="">
        Thanks for giving it a go! We&apos;re excited to have you on board.
      </p>
      <div className="flex flex-col gap-1 border px-6 py-4">
        <p className="pb-4 text-2xl font-bold">BETA</p>
        <p>&#47;&apos;bi&#58;tə&#47;</p>
        <Separator data-sentry-element="Separator" data-sentry-source-file="onboarding-steps.tsx" />
        <p className="">
          <span className="me-1 italic text-muted-foreground">(noun)</span>
          the second letter of the Greek alphabet
          <ol className="ms-6 list-disc pt-2">
            <li className="">Functionality: Limited</li>
            <li className="">Bugs: Guaranteed</li>
          </ol>
        </p>
      </div>
      <p className="">
        At the bottom of the sidebar, there is a feedback button. Please use it
        whenever you
        <ul className="list-decimal pl-4 pt-2 text-sm text-muted-foreground">
          <li className="">Encounter a bug</li>
          <li className="">See an unexpected behaviour</li>
          <li className="">Have an improvement suggestion</li>
          <li className="">Would like to request a missing feature</li>
          <li className="">Have a question</li>
          <li className="">Want to get in touch</li>
          <li className="">Something else</li>
        </ul>
      </p>
      <Button onClick={() => moveToNextStep()} data-sentry-element="Button" data-sentry-source-file="onboarding-steps.tsx">
        <span>
          What is <FairLogo className="font-bold" data-sentry-element="FairLogo" data-sentry-source-file="onboarding-steps.tsx" />?
        </span>
        <ArrowRight className="size-4 shrink-0" data-sentry-element="ArrowRight" data-sentry-source-file="onboarding-steps.tsx" />
      </Button>
    </div>;
}
export function WhatIsFair({
  moveToNextStep
}: OnboardingContentProps) {
  return <div className="flex flex-col gap-4 animate-in fade-in slide-in-from-bottom-4" data-sentry-component="WhatIsFair" data-sentry-source-file="onboarding-steps.tsx">
      <p className="text-sm">
        <FairLogo className="font-bold" data-sentry-element="FairLogo" data-sentry-source-file="onboarding-steps.tsx" /> aims to replace the the current AI
        chatbots with 2 simple features:
      </p>
      <div className="grid gap-4 sm:grid-cols-2">
        <Card data-sentry-element="Card" data-sentry-source-file="onboarding-steps.tsx">
          <CardHeader className="flex flex-row items-center gap-2 space-y-0" data-sentry-element="CardHeader" data-sentry-source-file="onboarding-steps.tsx">
            <Coins className="size-5 shrink-0" data-sentry-element="Coins" data-sentry-source-file="onboarding-steps.tsx" />
            <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="onboarding-steps.tsx">Pay-as-you-go</CardTitle>
          </CardHeader>
          <CardContent data-sentry-element="CardContent" data-sentry-source-file="onboarding-steps.tsx">
            <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="onboarding-steps.tsx">
              Buy credits and use them as you chat. Only pay for what you use!
            </CardDescription>
          </CardContent>
        </Card>
        <Card data-sentry-element="Card" data-sentry-source-file="onboarding-steps.tsx">
          <CardHeader className="flex flex-row items-center gap-2 space-y-0" data-sentry-element="CardHeader" data-sentry-source-file="onboarding-steps.tsx">
            <Bot className="size-5 shrink-0" data-sentry-element="Bot" data-sentry-source-file="onboarding-steps.tsx" />
            <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="onboarding-steps.tsx">Multiple Models</CardTitle>
          </CardHeader>
          <CardContent data-sentry-element="CardContent" data-sentry-source-file="onboarding-steps.tsx">
            <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="onboarding-steps.tsx">
              Explore and interact with various language models. No more
              choosing which subscription to buy!
            </CardDescription>
          </CardContent>
        </Card>
      </div>
      <Button onClick={() => moveToNextStep()} data-sentry-element="Button" data-sentry-source-file="onboarding-steps.tsx">
        Set up your account
        <ArrowRight className="size-4 shrink-0" data-sentry-element="ArrowRight" data-sentry-source-file="onboarding-steps.tsx" />
      </Button>
    </div>;
}
export function LetsSetUpYourAccount({
  user,
  updateUser,
  moveToNextStep
}: OnboardingContentProps) {
  const [username, setUsername] = useState(user.username || '');
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [availableModels, setAvailableModels] = useState<SupabaseModel[]>([]);
  const [model, setModel] = useState<SupabaseModel>();
  const [newsletter, setNewsletter] = useState(true);
  const [isSubmitting, startSubmition] = useTransition();
  const router = useRouter();
  useEffect(() => {
    fetchModels().then(({
      data: models,
      error
    }) => {
      if (error) {
        toast.error('Error loading LLM models');
        captureException(error);
        return;
      }
      setAvailableModels(models);
      setModel(user.default_llm?.is_active ? user.default_llm : models.find(m => m.name === 'GPT 4o Mini'));
    });
  }, [user.default_llm, user.default_llm_id]);
  return <div className="flex flex-col gap-8 animate-in fade-in slide-in-from-bottom-4" data-sentry-component="LetsSetUpYourAccount" data-sentry-source-file="onboarding-steps.tsx">
      {/* Username */}
      <div className="flex flex-col gap-2">
        <p>What username would you like to use?</p>
        <Input placeholder="Username" className={cn(usernameError && 'border-destructive')} value={username} onChange={e => {
        setUsername(e.target.value);
        if (e.target.value) setUsernameError(null);else setUsernameError('Username is required');
      }} data-sentry-element="Input" data-sentry-source-file="onboarding-steps.tsx" />
        {usernameError ? <p className="flex items-center gap-1 text-xs text-destructive">
            <CircleAlert className="size-3 shrink-0" />
            Username is required
          </p> : <p className="flex items-center gap-1 text-xs text-muted-foreground">
            <Info className="size-3 shrink-0" />
            Other users will see this name
          </p>}
      </div>
      {/* Default LLM */}
      <div className="flex flex-col gap-2">
        <p>What LLM model should we set as your default?</p>
        <ModelPicker availableModels={availableModels} model={model} setModel={m => !!m && setModel(m)} title="Choose your default LLM" data-sentry-element="ModelPicker" data-sentry-source-file="onboarding-steps.tsx" />
        <p className="flex items-center gap-1 text-xs text-muted-foreground">
          <Info className="size-3 shrink-0" data-sentry-element="Info" data-sentry-source-file="onboarding-steps.tsx" />
          New chats will use this model by default
        </p>
      </div>
      {/* Product newsletter */}
      <div className="items-top flex space-x-2">
        <Checkbox id="newsletter" checked={newsletter} onCheckedChange={v => setNewsletter(v === 'indeterminate' ? false : v)} data-sentry-element="Checkbox" data-sentry-source-file="onboarding-steps.tsx" />
        <div className="grid gap-1.5 leading-none">
          <label htmlFor="newsletter" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            Subscribe to our product newsletter
          </label>
          <p className="text-sm text-muted-foreground">
            While we are in beta, we heavily recommend subscribing to the
            product newsletter to know what features to test.
          </p>
        </div>
      </div>
      <Button disabled={isSubmitting} onClick={() => startSubmition(async () => {
      if (!username) {
        setUsernameError('Username is required');
        return;
      }
      let resend_id: string | undefined = undefined;
      if (newsletter && user.email) {
        const {
          data,
          error
        } = await addUserToResend(user.email);
        if (error) {
          captureException(error);
          toast.error('There was an error subscribing you to the newsletter. We will examine this and add you manually later.');
        } else {
          resend_id = data!.id;
        }
      }
      const newUser = await updateUser({
        default_llm_id: model?.id,
        resend_id,
        username
      });
      if (!newUser) return;
      const res = await createBrowserSupabase().from('billing_accounts').update({
        name: `${username}'s billing account`
      }).eq('id', newUser.user_billing_accounts[0].billing_account_id);
      if (res.error) {
        toast.error('Failed to update billing account');
        captureException(res.error);
        return;
      }
      router.refresh();
      moveToNextStep();
    })} data-sentry-element="Button" data-sentry-source-file="onboarding-steps.tsx">
        Continue
        {isSubmitting ? <LoaderCircle strokeWidth={1} className="size-4 shrink-0 animate-spin" /> : <ArrowRight className="size-4 shrink-0" />}
      </Button>
    </div>;
}
export function BillingAccounts({
  user,
  moveToNextStep
}: OnboardingContentProps) {
  return <div className="flex max-w-md flex-col gap-3 text-sm animate-in fade-in slide-in-from-bottom-4" data-sentry-component="BillingAccounts" data-sentry-source-file="onboarding-steps.tsx">
      <p className="pb-4 text-base font-medium">
        When using <FairLogo className="inline-block" data-sentry-element="FairLogo" data-sentry-source-file="onboarding-steps.tsx" />, you&apos;ll spend
        credits that are associated with a billing account.
      </p>
      <p className="text-muted-foreground">
        We&apos;ve created an initial billing account for you. You can create
        additional accounts and invite team members for centralized billing.
      </p>
      <div className="space-y-3">
        {user.user_billing_accounts.map((billingAccount, index) => <div key={index} className="rounded-lg border border-gray-200 p-4 shadow">
            <h4 className="font-medium">
              {billingAccount.billing_account?.name}
            </h4>
            <p className="mt-1 text-xs text-muted-foreground">
              Default Account
            </p>
          </div>)}
      </div>
      <p className="text-muted-foreground">
        <Info className="me-2 inline size-3.5 shrink-0" data-sentry-element="Info" data-sentry-source-file="onboarding-steps.tsx" />
        You can manage your billing accounts in your account settings.
      </p>
      <Button onClick={() => moveToNextStep()} className="mt-4 sm:w-auto" data-sentry-element="Button" data-sentry-source-file="onboarding-steps.tsx">
        Continue
        <ArrowRight className="ml-2 size-4 shrink-0" data-sentry-element="ArrowRight" data-sentry-source-file="onboarding-steps.tsx" />
      </Button>
    </div>;
}
export function ClaimFreeCredits({
  user,
  updateUser,
  moveToNextStep
}: OnboardingContentProps) {
  const [isSubmitting, startSubmition] = useTransition();
  return <div className="flex flex-col gap-4 animate-in fade-in slide-in-from-bottom-4" data-sentry-component="ClaimFreeCredits" data-sentry-source-file="onboarding-steps.tsx">
      <h4 className="text-lg">
        To get you started, we are giving you{' '}
        <span className="rounded-lg bg-green-500 px-2 py-1 font-mono font-bold">
          $0.50
        </span>{' '}
        in{' '}
        <span className="font-semibold italic text-green-500">
          free credits{' '}
        </span>
        to share your thoughts with us.
      </h4>
      <p className="text-muted-foreground">
        It might not seem like much, but you will be surprised, just how cheap
        AI can be!
      </p>
      <div className="space-y-2">
        <p className="text-sm text-muted-foreground">
          The billing account to add the credits to:
        </p>
        <Select defaultValue={user.user_billing_accounts[0].billing_account_id} data-sentry-element="Select" data-sentry-source-file="onboarding-steps.tsx">
          <SelectTrigger className="w-full" data-sentry-element="SelectTrigger" data-sentry-source-file="onboarding-steps.tsx">
            <SelectValue placeholder="Theme" data-sentry-element="SelectValue" data-sentry-source-file="onboarding-steps.tsx" />
          </SelectTrigger>
          <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="onboarding-steps.tsx">
            {user.user_billing_accounts.map(ba => <SelectItem key={ba.billing_account_id + ba.user_id} value={ba.billing_account_id}>
                {ba.billing_account?.name}
              </SelectItem>)}
          </SelectContent>
        </Select>
      </div>
      <Button disabled={isSubmitting} onClick={() => startSubmition(async () => {
      const error = await createStartingBonusTransaction(user.id, user.user_billing_accounts[0].billing_account_id);
      if (error) {
        toast.error('Failed to create bonus transaction');
        return;
      }
      moveToNextStep();
    })} data-sentry-element="Button" data-sentry-source-file="onboarding-steps.tsx">
        Start chatting!
        {isSubmitting ? <LoaderCircle strokeWidth={1} className="size-4 shrink-0 animate-spin" /> : <MessageCircle className="size-4 shrink-0" />}
      </Button>
    </div>;
}
export function CouponForBetaTesters({
  user,
  updateUser,
  moveToNextStep
}: OnboardingContentProps) {
  const [isSubmitting, startSubmition] = useTransition();
  return <div className="flex flex-col gap-8 animate-in fade-in slide-in-from-bottom-4" data-sentry-component="CouponForBetaTesters" data-sentry-source-file="onboarding-steps.tsx">
      <p>
        We value your feedback as a beta tester so much, we decide to give you a
        $5 discount on your first credit purchase.
      </p>
      <p className="-mt-3 flex items-center gap-2 rounded-lg border px-3 py-2 text-sm text-muted-foreground">
        <Info className="size-3.5 shrink-0" data-sentry-element="Info" data-sentry-source-file="onboarding-steps.tsx" />
        <span>
          Go ahead right now and buy $5 in credits for{' '}
          <span className="font-bold text-green-500">$0</span>
        </span>
      </p>
      <div className="shadox-lg rounded-2xl border border-dashed border-primary p-4 text-start">
        <h3 className="flex items-center justify-between gap-2 text-xl font-semibold">
          Beta Tester Reward
          <FlaskConical className="ml-2 size-8 shrink-0 text-fuchsia-500" strokeWidth={2} data-sentry-element="FlaskConical" data-sentry-source-file="onboarding-steps.tsx" />
        </h3>
        <p className="my-6 text-3xl font-bold text-primary">
          <span className="rounded-lg bg-green-500 px-2 py-1 font-mono">
            $5
          </span>{' '}
          <span className="italic">Discount</span>
        </p>
        <p className="text-sm text-muted-foreground">Valid for 90 days</p>
        <p className="text-sm text-muted-foreground">
          Applies automatically in basket
        </p>
      </div>
      <Button disabled={isSubmitting} onClick={() => startSubmition(async () => {
      const {
        error
      } = await createBetaTesterCoupon(user.id);
      if (error) {
        toast.error('Failed to create coupon. We will examine this and create one manually later. Feel free to contact us if there are any issues');
      }
      moveToNextStep();
    })} data-sentry-element="Button" data-sentry-source-file="onboarding-steps.tsx">
        Great!
        {isSubmitting ? <LoaderCircle strokeWidth={1} className="ml-2 size-4 shrink-0 animate-spin" /> : <Coins className="ml-2 size-4 shrink-0" />}
      </Button>
    </div>;
}