import { UserHookState } from '@/lib/supabase/browser';
import { FairLogo } from '../fair-logo';
import { BillingAccounts, ClaimFreeCredits, CouponForBetaTesters, LetsSetUpYourAccount, WelcomeToBetaTesting, WhatIsFair } from './onboarding-steps';
export type OnboardingContentProps = {
  user: NonNullable<UserHookState['data']>;
  updateUser: UserHookState['update'];
  moveToNextStep: (save?: boolean) => void;
};
export const ONBOARDING_STEPS: {
  id: string;
  title: React.ReactNode;
  description?: React.ReactNode;
  content: React.FC<OnboardingContentProps>;
}[] = [{
  id: 'welcome_to_beta_1.0',
  title: <>
        Welcome to the beta version of <FairLogo />!
      </>,
  content: WelcomeToBetaTesting
}, {
  id: 'what_is_fair_1.0',
  title: <>
        What is <FairLogo />?
      </>,
  content: WhatIsFair
}, {
  id: 'account_setup_1.0',
  title: "Let's set up your account",
  content: LetsSetUpYourAccount
}, {
  id: 'billing_accounts_1.0',
  title: 'Your billing account',
  content: BillingAccounts
}, {
  id: 'free_starting_credits_1.0',
  title: 'Penny for your thoughts? How about 50¢?',
  content: ClaimFreeCredits
}, {
  id: 'coupon_for_beta_testers_1.0',
  title: 'One more thing, while we are in beta',
  content: CouponForBetaTesters
}] as const;