'use client';

import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
const ConfettiContext = createContext<{
  trigger: () => void;
  isTriggered: boolean;
  cancel: () => void;
} | null>(null);
export function ConfettiProvider({
  children
}: PropsWithChildren) {
  const [isTriggered, setIsTriggered] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const unmountTimout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (!isTriggered) {
      unmountTimout.current = setTimeout(() => {
        setIsMounted(false);
      }, 10000);
      return () => {
        clearTimeout(unmountTimout.current);
        unmountTimout.current = undefined;
      };
    } else {
      setIsMounted(true);
    }
  }, [isTriggered]);
  const trigger = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }
    setIsTriggered(true);
    setTimeout(() => {
      setIsTriggered(false);
    }, 2000);
  }, []);
  const cancel = useCallback(() => {
    setIsTriggered(false);
  }, []);
  return <ConfettiContext.Provider value={{
    trigger,
    isTriggered,
    cancel
  }} data-sentry-element="unknown" data-sentry-component="ConfettiProvider" data-sentry-source-file="use-confetti.tsx">
      {children}
      {isMounted && <div className="fixed inset-0 z-[100]">
          <Confetti numberOfPieces={isTriggered ? 200 : 0} />
        </div>}
    </ConfettiContext.Provider>;
}
export function useConfetti() {
  const context = useContext(ConfettiContext);
  if (!context) {
    throw new Error('useConfetti must be used within a ConfettiProvider');
  }
  return context;
}