import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["OnboardingDialog"] */ "/vercel/path0/components/onboarding/onboarding-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CheckoutRedirectModal"] */ "/vercel/path0/components/payments/checkout-redirect-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TouchProvider"] */ "/vercel/path0/components/ui/hybrid-tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfettiProvider"] */ "/vercel/path0/lib/hooks/use-confetti.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/vercel/path0/lib/hooks/use-sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogProvider"] */ "/vercel/path0/lib/posthog/posthog-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/lib/supabase/browser.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBarProvider","ProgressBar"] */ "/vercel/path0/node_modules/react-transition-progress/dist/es/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
