'use client';

import dynamic from 'next/dynamic';
import posthog from 'posthog-js';
import { PostHogProvider as RawPostHogProvider } from 'posthog-js/react';
const PostHogPageView = dynamic(() => import('./posthog-pageview'), {
  ssr: false
});
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: '/ingest',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: 'always',
    capture_pageview: false,
    // Disable automatic pageview capture, as we capture manually
    capture_pageleave: true
  });
}
export function PostHogProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <RawPostHogProvider client={posthog} data-sentry-element="RawPostHogProvider" data-sentry-component="PostHogProvider" data-sentry-source-file="posthog-context.tsx">
      {children}
      <PostHogPageView data-sentry-element="PostHogPageView" data-sentry-source-file="posthog-context.tsx" />
    </RawPostHogProvider>;
}